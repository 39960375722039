import React from "react";
import data from "../../data.json";
import styled from "styled-components";
import { GlobalContext } from "../../contexts/GlobalContext";
import {
	StyledContainer,
	StylePageDescription,
	StyledSection,
	StyledBox,
	StyledBoxTitle,
	StyledCard,
	StyledCardImage,
	StyledParagraph,
} from "../../UI/Variables";

import slide01 from "../../Assets/slide01.png";
import slide02 from "../../Assets/slide02.png";
import slide03 from "../../Assets/slide03.png";

import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument, usePrismicDocumentByUID } from "@prismicio/react";
import { RichText } from "prismic-dom";

const HStyledImage = styled.img`
  display: block;
  width: 100%;
`;
const StyledTitle = styled.h1`
  padding: 18px 0px;
  text-align: center;
  background: var(--c-yellow-dark);
  margin-bottom: 32px;
`;

const StyledImage = styled.img`
  width: 50%;
  display: block;
  margin: auto;
  border-radius: 8px;
  margin-bottom: 32px;

  @media (max-width: 782px) {
    width: 90%;
  }
`;

const UnityImageGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 46px;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

`
const UnityHorizontalGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  > div{
    height: 380px;

    > img{
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }
  }
`

const UnityVerticalGrid = styled.div`
  > div{
    height: 800px;
    width: 540px;

    > img{
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }
  }
`

const UnityMobileContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;

  > img{
    width: 100%;
    border-radius: 4px;
  }
`

const UnityText = () => {
  window.scrollTo(0, 0);
  const global = React.useContext(GlobalContext);
  const [isMobile, setItMobile] = React.useState(false);

  function getDevice() {
    if (window.screen.width <= 798) {
      setItMobile(true);
    }
  }

  React.useEffect(() => {
    getDevice();
  }, []);

  if(!localStorage.getItem("NAME_UNITY")) {
    global.setArticle("Espaço Julyo Ganiko");
    localStorage.setItem("NAME_UNITY", "Espaço Julyo Ganiko");
  };

  const unityGlobal =
    global.titleUnity !== ""
      ? global.titleUnity
      : localStorage.getItem("NAME_UNITY");

  const [unityData, setUnityData] = React.useState();
  const [unity, statusUnity] = usePrismicDocumentByUID('unity_text', unityGlobal)
  const [unityImageGrid, setUnityImageGrid] = React.useState();

  React.useEffect(() => {
    if (statusUnity.state === 'loaded') {
      setUnityData(unity.data)
    }
  }, [statusUnity]);

  React.useEffect(() => {
    if(unityData && unityData.unity_images.length > 0){
      setUnityImageGrid(handleimageGrid(unityData.unity_images))
    }
  }, [unityData]);

  function handleimageGrid(images) {
    let gridImages = []
    let limit = 3

    for(let i = 0; i < images.length; i += limit) {
      let chunk = images.slice(i, i + limit)
      gridImages.push(chunk)
    }

    return gridImages
  }

  return (
    <div>
      <StyledTitle>{unityData && unityData.title[0].text}</StyledTitle>

      <StyledContainer>
        <StyledImage src={unityData && unityData.image.url} alt={unityData && unityData.image.alt} />
        <StyledSection dangerouslySetInnerHTML={unityData && { __html: RichText.asHtml(unityData.description) }}></StyledSection>

        {!isMobile && unityImageGrid && unityImageGrid.length > 0 && unityImageGrid.map((item) => (
        <UnityImageGrid>
          
          <UnityHorizontalGrid>
              {item.filter((child) => child.unity_image_type === 'Paisagem').map((child, index) => (
                <div key={child.unity_image.alt + index}>
                  <img src={child.unity_image.url} alt={child.unity_image.alt} />
              </div>
              ))}
          </UnityHorizontalGrid>
          
          <UnityVerticalGrid>
              {item.filter((child) => child.unity_image_type === 'Retrato').map((child, index) => (
                <div key={child.unity_image.alt + index}>
                  <img src={child.unity_image.url} alt={child.unity_image.alt} />
              </div>
              ))}
          </UnityVerticalGrid>

        </UnityImageGrid>
        ))}

        {isMobile && unityData && unityData.unity_images.length > 0 && unityData.unity_images.map((item, index) => (
          <UnityMobileContainer key={item.unity_image.alt + index}>
            <img src={item.unity_image.url} alt={item.unity_image.alt} />
          </UnityMobileContainer>
        ))}
      </StyledContainer>
    </div>
  );
};

export default UnityText;
