import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../Assets/spa_logo.png";
import { StyledContainer } from "../../UI/Variables";
import { Link, NavLink } from "react-router-dom";
import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-dom";

const StyledHeader = styled.header`
  background: var(--c-yellow-more-light);
`;

const StyledButton = styled.button`
  padding: 6px 26px;
  border: none;
  border-radius: 4px;
  background: #fff3ca;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    padding: 10px 30px;
  }
`;

const StyledImage = styled.img`
  width: 124px;
  @media (max-width: 782px) {
    width: 108px;
    margin-bottom: 12px;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 94px;

  @media (max-width: 782px) {
    flex-direction: column;
    padding: 15px 32px;
  }
`;
const StyledListContainer = styled.div`
  display: flex;
  gap: 22px;
`;
const StyledList = styled.ul`
  display: flex;

  @media (max-width: 782px) {
    flex-direction: column;
    gap: 6px;
  }

  &:last-of-type {
    align-items: end;
  }
`;

const StyledItem = styled.li`
  margin-left: 18px;
  font-size: 14px;
  text-transform: uppercase;
  color: #2b2b2b;
  font-weight: 700;

  &:hover {
    font-size: 16px;
  }

  &:focus {
    font-size: 16px;
  }

  @media (min-width: 401px) and (max-width: 782px) {
    font-size: 12px;

    &:hover {
      font-size: 12px;
    }

    &:focus {
      font-size: 12px;
    }
  }

  @media (max-width: 400px) {
    font-size: 11px;

    &:hover {
      font-size: 11px;
    }

    &:focus {
      font-size: 11px;
    }
  }
`;

const StyledBanner = styled.div`
  background: var(--c-brown);
`;

const StyledTitleBanner = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
  letter-spacing: 1px;
`;

const StyledNavBanner = styled(StyledNav)`
  justify-content: space-around;
  padding: 15px 0;
  flex-direction: row;
`;

const Header = () => {
  const [isMobile, setItMobile] = useState(false);
  const [isTablet, setItTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [dataHeaderFormatted, setDataHeaderFormatted] = React.useState();
  const [dataHeader, statusHeader] = useSinglePrismicDocument('header');

  React.useEffect(() => {
    if (statusHeader.state === "loaded") {
      setDataHeaderFormatted(dataHeader.data);
    }
  }, [statusHeader]);

  function getDevice() {
    if (window.screen.width <= 640) {
      setItMobile(true);
    } else if (window.screen.width > 640 && window.screen.width <= 768) {
      setItTablet(true);
    } else {
      setIsDesktop(true);
    }
  }

  useEffect(() => {
    getDevice();
  }, []);

  return (
    <StyledHeader>
      <StyledBanner>
        <StyledContainer>
          <StyledNavBanner>
            <StyledTitleBanner>{dataHeaderFormatted && dataHeaderFormatted.title_call_to_action[0].text}</StyledTitleBanner>
            <a
              href={dataHeaderFormatted && dataHeaderFormatted.link_call_to_action.url}
              target={dataHeaderFormatted && dataHeaderFormatted.link_call_to_action.target}
              rel="noopener noreferrer"
            >
              <StyledButton>reservar</StyledButton>
            </a>
          </StyledNavBanner>
        </StyledContainer>
      </StyledBanner>

      <StyledContainer>
        <StyledNav>
          <NavLink to="/">
            <StyledImage src={dataHeaderFormatted && dataHeaderFormatted.logo.url} alt={dataHeaderFormatted && dataHeaderFormatted.logo.alt}></StyledImage>
          </NavLink>
          {isDesktop && (
            <StyledList>
              {dataHeaderFormatted && dataHeaderFormatted.menu_link.map((item, index) => (
                <StyledItem key={item.title[0].text + index}>
                  <NavLink to={item.relationship.type}>{item.title[0].text}</NavLink>
                </StyledItem>
              ))}

              <StyledItem>
                <NavLink to="contact">Contato</NavLink>
              </StyledItem>
            </StyledList>
          )}
          {(isMobile || isTablet) && (
            <StyledListContainer>
              <StyledList>
                <StyledItem>
                  <NavLink to="unity">nossas unidades</NavLink>
                </StyledItem>
                <StyledItem>
                  <NavLink to="article">artigos</NavLink>
                </StyledItem>
                <StyledItem>
                  <NavLink to="contact">Contato</NavLink>
                </StyledItem>
              </StyledList>

              <StyledList>
                <StyledItem>
                  <NavLink to="therapy">nossos serviços</NavLink>
                </StyledItem>
                <StyledItem>
                  <NavLink to="day_spa">presenteie</NavLink>
                </StyledItem>
              </StyledList>
            </StyledListContainer>
          )}
        </StyledNav>
      </StyledContainer>
    </StyledHeader>
  );
};

export default Header;
