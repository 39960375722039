import styled from "styled-components";

export const StyledContainer = styled.div`
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
`;

export const StylePageDescription = styled.p`
	font-family: var(--type-first);
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	color: var(--c-black);
	text-align: center;
	margin-bottom: 16px;
`;

export const StyledParagraph = styled.p`
  word-spacing: 5px;
  margin-bottom: 24px;
  padding: 0px 20px;
  font-family: var(--type-third);
  line-height: 24px;
`;

export const StyledSection = styled.section`
  margin-top: 32px;
  max-width: 1024px;
  display: block;
  margin: auto;

  &:first-of-type {
    margin-top: 20px;
  }

  &:last-of-type {
    margin-bottom: 32px;
  }
`;

export const StyledBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 28px;

  @media (max-width: 782px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledBoxTitle = styled.h2`
  font-family: var(--type-first);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: var(--c-black);
  letter-spacing: -0.5px;
  text-align: center;
  width: 380px;
  margin: 0 auto 6px auto;

  @media (max-width: 782px) {
		width: 320px;
	}
`;

export const StyledCard = styled.div`
	width: 440px;
	background: var(--c-yellow);
	border-radius: 8px;
	padding-bottom: 16px;

	@media (max-width: 782px) {
		width: 320px;
	}
`;

export const StyledCardImage = styled.img`
	width: 440px;
	border-radius: 8px 8px 0 0;

	@media (max-width: 782px) {
		width: 320px;
	}
`;
