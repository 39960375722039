import React from "react";
import styled from "styled-components";
import {
	StyledContainer,
	StyledBox,
	StyledBoxTitle,
	StyledCard,
	StyledCardImage,
	StylePageDescription,
	StyledSection,
} from "../../UI/Variables";
import icon from "../../Assets/whatsapp.png";

const StyledWhats = styled.img`
	position: fixed;
	right: 30px;
	bottom: 50px;
	width: 7%;
	z-index: 10;

	@media (max-width: 782px) {
		right: 6px;
		width: 20%;
	}
`;

function Whatsapp() {
	return (
		<StyledContainer>
			<a href="https://wa.me/5511952580001" target="blank">
				<StyledWhats src={icon} alt="Ícone WhatsApp" />
			</a>
		</StyledContainer>
	);
}

export default Whatsapp;
