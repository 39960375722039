import React from "react";
import styled from "styled-components";
import {
	StyledContainer,
	StylePageDescription,
	StyledBox,
	StyledBoxTitle,
	StyledCard,
	StyledCardImage,
	StyledSection,
} from "../../UI/Variables";

import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-dom";

const StyledPageTitle = styled.h1`
  font-family: var(--type-first);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: var(--c-black);
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 16px;

  &::after {
    content: "";
    display: block;
    width: 165px;
    height: 2px;
    background: #000;
    margin: 0 auto;
  }

  &:last-of-type {
    margin-top: 32px;
  }
`;

const StyledCardImageDay = styled(StyledCardImage)`
	object-fit: cover;
    object-position: center;
    height: 300px;
`;

const StyledTextPrismic = styled.div`
  p{
    font-family: var(--type-first);
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: var(--c-black);
    text-align: center;
    margin-bottom: 16px;
  }
`

const StyledCardPrismic = styled.div`
  min-height: 280px;
  display: flex;
flex-direction: column;
    justify-content: center;
  
  p{
	padding: 0 16px;
    text-align: center;
	margin-top: 18px;

	&:last-of-type {
		margin-top: 0px;
	}

	> strong{
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
	}
  }

  span{
	font-family: var(--type-first);
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	color: var(--c-black);
	letter-spacing: -0.5px;
  }

  ul{
	margin-left: 34px;
	margin-top: 16px;

	li{
		list-style: disc;
	}
  }
`

const DaySpa = () => {
	window.scrollTo(0, 0);

	const [dataDaySpa, statusDaySpa] = useSinglePrismicDocument('day_spa');
	const [dataDaySpaFormated, setDataDaySpaFormated] = React.useState(null);

	const [dataDaySpaText, statusDaySpaText] = useAllPrismicDocumentsByType('day_spa_text');
	const [dataDaySpaCasal, setDataDaySpaCasal] = React.useState([]);
	const [dataDaySpaIndividual, setDataDaySpaIndividual] = React.useState([]);

	React.useEffect(() => {
		if (statusDaySpa.state === "loaded") {
			setDataDaySpaFormated(dataDaySpa.data);
		}
	}, [statusDaySpa]);


	React.useEffect(() => {
		if (statusDaySpaText.state === "loaded") {
			dataDaySpaText.forEach((item) => {
				if (item.data.day_spa_type === "individual") {
					setDataDaySpaIndividual(prevState => [...prevState, item.data]);
				}
			
				if (item.data.day_spa_type === "casal") {
					setDataDaySpaCasal(prevState => [...prevState, item.data]);
				}
			})
		}
	}, [statusDaySpaText]);
	
	return (
		<StyledContainer>
			<StyledSection>
				<StyledTextPrismic dangerouslySetInnerHTML={dataDaySpaFormated && { __html: RichText.asHtml(dataDaySpaFormated.description) }}></StyledTextPrismic>
			</StyledSection>

			<StyledSection>
				<StyledPageTitle>Individuais</StyledPageTitle>
				<StyledBox>
					{dataDaySpaIndividual.length > 0 && dataDaySpaIndividual.map((item, index) => (
						<div key={item.title[0].text + index}>
							<StyledBoxTitle>{item.title[0].text}</StyledBoxTitle>
							<StyledCard>
								<StyledCardImageDay src={item.image.url} alt={item.image.alt} />
								<StyledCardPrismic dangerouslySetInnerHTML={{ __html: RichText.asHtml(item.description) }}></StyledCardPrismic>
							</StyledCard>
						</div>
					))}
				</StyledBox>
			</StyledSection>

			<StyledSection>
				<StyledPageTitle>Casal</StyledPageTitle>
				{dataDaySpaCasal.length > 0 && dataDaySpaCasal.map((item, index) => (
					<StyledBox key={item.title[0].text + index}>
						<div>
							<StyledBoxTitle>{item.title[0].text}</StyledBoxTitle>
							<StyledCard>
								<StyledCardImageDay src={item.image.url} alt={item.image.alt} />
									<StyledCardPrismic dangerouslySetInnerHTML={{ __html: RichText.asHtml(item.description) }}></StyledCardPrismic>
							</StyledCard>
						</div>
				</StyledBox>
				))}
			</StyledSection>
		</StyledContainer>
	);
};

export default DaySpa;
