import React from "react";

export const GlobalContext = React.createContext();

export const NamesText = ({ children }) => {
	const [titleTherapy, setTitleTherapy] = React.useState("");
	const [titleUnity, setTitleUnity] = React.useState("");
	const [titleArticle, setTitleArticle] = React.useState("");

	function setTherapy(name) {
		setTitleTherapy(name);
	}

	function setUnity(name) {
		setTitleUnity(name);
	}

	function setArticle(name) {
		setTitleArticle(name);
	}

	return (
		<GlobalContext.Provider
			value={{ titleTherapy, setTherapy, titleUnity, setUnity, titleArticle, setArticle }}
		>
			{children}
		</GlobalContext.Provider>
	);
};
