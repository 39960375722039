import React from "react";
import data from "../../data.json";
import styled from "styled-components";
import { GlobalContext } from "../../contexts/GlobalContext";
import {
	StyledContainer,
	StylePageDescription,
	StyledSection,
	StyledBox,
	StyledBoxTitle,
	StyledCard,
	StyledCardImage,
	StyledParagraph,
} from "../../UI/Variables";

const StyledTitle = styled.h1`
	padding: 18px 0px;
	text-align: center;
	background: var(--c-yellow-dark);
	margin-bottom: 32px;
`;

const Video = () => {
  window.scrollTo(0, 0);

  

  return (
    <div>
      <StyledTitle>VIDEO DO LIVRO</StyledTitle>

      <StyledContainer>

      </StyledContainer>
    </div>
  );
};

export default Video;
