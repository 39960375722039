import React from "react";
import styled from "styled-components";
import { StyledContainer } from "../../UI/Variables";
import { ReactComponent as Insta } from "../../Assets/instagram.svg";
import { ReactComponent as Face } from "../../Assets/facebook.svg";
import { ReactComponent as Tiktok } from "../../Assets/tiktok.svg";
import logo from "../../Assets/ganikoInside02.png";
import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-dom";

const StyledFooter = styled.footer`
	background: #fff3ca;
	padding: 20px 0;
`;

const StyledItens = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  grid-gap: 60px;
  margin-bottom: 18px;
  padding: 0 94px;

  @media (max-width: 782px) {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    grid-gap: 20px;
  }
`;

const StyledBox = styled.div`
	align-self: ${({ self }) => self};
`;

const StyledTitle = styled.h1`
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 22px;
`;

const StyledDescription = styled.p`
	font-size: 16px;
`;

const StyledSocial = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
`;

const StyleCopy = styled.span`
  color: gray;
  font-size: 16px;
  font-style: italic;
`;

const StyledCreator = styled.div`
  display: flex;
  margin-top: 28px;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  const [dataFooterFormatted, setDataFooterFormatted] = React.useState();
  const [dataFooter, statusFooter] = useSinglePrismicDocument('footer');

  React.useEffect(() => {
    if (statusFooter.state === "loaded") {
      setDataFooterFormatted(dataFooter.data);
    }
  }, [statusFooter]);

  return (
    <StyledFooter>
      <StyledContainer>
        <StyledItens>
          <StyledBox self="center">
            <StyledDescription dangerouslySetInnerHTML={dataFooterFormatted && { __html: RichText.asHtml(dataFooterFormatted.description) }}></StyledDescription>
          </StyledBox>

          <StyledBox self="baseline">
            <StyledTitle>{}</StyledTitle>
            <StyledDescription dangerouslySetInnerHTML={dataFooterFormatted && { __html: RichText.asHtml(dataFooterFormatted.contact_description) }}></StyledDescription>
          </StyledBox>

          <StyledBox self="baseline">
            <StyledTitle>{dataFooterFormatted && dataFooterFormatted.social_media_title[0].text}</StyledTitle>
            <StyledSocial>
              {dataFooterFormatted && dataFooterFormatted.social_media_icons.length > 0 && dataFooterFormatted.social_media_icons.map((item, index) => (
                <a href={item.social_media_link.url} key={item.social_media_image.alt + index}>
                  <img src={item.social_media_image.url} alt={item.social_media_image.alt} />
                </a>
              ))}
            </StyledSocial>
          </StyledBox>
        </StyledItens>
        <StyledCreator>
          <StyleCopy>Criado por</StyleCopy>
          <a
            href="https://www.instagram.com/ganiko_inside/"
            target="_blank"
            rel="noreferrer"
          >
            <img style={{ width: 200, marginLeft: 16 }} src={dataFooterFormatted && dataFooterFormatted.image.url} alt={dataFooterFormatted && dataFooterFormatted.image.alt} />
          </a>
        </StyledCreator>
      </StyledContainer>
    </StyledFooter>
  );
};

export default Footer;
