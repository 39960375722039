import React from "react";
import styled from "styled-components";
import data from "../../data.json";
import {
  StyledContainer,
  StyledBox,
  StyledBoxTitle,
  StyledCard,
  StyledCardImage,
  StyledSection,
} from "../../UI/Variables";
import { GlobalContext } from "../../contexts/GlobalContext";
import { Link, NavLink, Routes, Route } from "react-router-dom";
import TherapyText from "../TherapyText/TherapyText";

import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-dom";

const TStyledCardImage = styled(StyledCardImage)`
  height: 290px;
  object-fit: cover;
  object-position: center;
`;

const TStyledBox = styled(StyledBox)`
  margin: 32px 0;
  align-items: end;

  @media (max-width: 782px) {
    align-items: center;
  }
`;

const StyledCardButton = styled.button`
  display: block;
  margin: 16px auto 0 auto;
  margin-top: 16px;
  padding: 6px 22px;
  background: var(--c-yellow-light);
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: var(--c-white);
    background: var(--c-brown);
  }
`;

const Therapy = () => {
  const global = React.useContext(GlobalContext);

  const [dataTherapy, statusTherapy] = useAllPrismicDocumentsByType('therapy_text');
	const [dataTherapyFormated, setDataTherapyFormated] = React.useState([]);

	React.useEffect(() => {
		if (statusTherapy.state === "loaded") {
			setDataTherapyFormated(dataTherapy);
		}
	}, [statusTherapy]);

  function handleClick(item) {
    global.setTherapy(item.uid);
    localStorage.setItem("NAME_THERAPY", item.uid);
  }

  return (
    <StyledContainer>
      <StyledSection>
        <TStyledBox>
          {dataTherapyFormated.length > 0 &&
            dataTherapyFormated.map((item, index) => (
              <div key={item.data.title[0].text + index}>
                <StyledBoxTitle>{item.data.title[0].text}</StyledBoxTitle>
                <StyledCard>
                  <TStyledCardImage src={item.data.image.url} alt={item.data.image.alt} />
                  <NavLink to="/therapy_text">
                    <StyledCardButton onClick={() => handleClick(item)}>
                      Saber mais...
                    </StyledCardButton>
                  </NavLink>
                </StyledCard>
              </div>
            ))}
        </TStyledBox>
      </StyledSection>
    </StyledContainer>
  );
};

export default Therapy;
