import { useEffect } from "react";
import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { saveAs } from 'file-saver';

function Backup() {
    const [dataHome, statusHome] = useSinglePrismicDocument('home');
    const [dataHeader, statusHeader] = useSinglePrismicDocument('header');
    const [dataFooter, statusFooter] = useSinglePrismicDocument('footer');
    const [dataArticle, statusArticle] = useSinglePrismicDocument('article');
    const [dataArticleText, statusArticleText] = useAllPrismicDocumentsByType('article_text');
    const [dataContact, statusContact] = useSinglePrismicDocument('contact');
    const [dataDaySpa, statusDaySpa] = useSinglePrismicDocument('day_spa');
    const [dataDaySpaText, statusDaySpaText] = useAllPrismicDocumentsByType('day_spa_text');
    const [dataTherapy, statusTherapy] = useSinglePrismicDocument('therapy');
    const [dataTherapyText, statusTherapyText] = useAllPrismicDocumentsByType('therapy_text');
    const [dataUnity, statusUnity] = useSinglePrismicDocument('unity');
    const [dataUnityText, statusUnityText] = useAllPrismicDocumentsByType('unity_text');

    useEffect(() => {
      handleBackup(dataHome, statusHome, 'home')
    }, [statusHome]);

    useEffect(() => {
      handleBackup(dataHeader, statusHeader, 'header')
    }, [statusHeader]);

    useEffect(() => {
      handleBackup(dataFooter, statusFooter, 'footer')
    }, [statusFooter]);

    useEffect(() => {
      handleBackup(dataDaySpa, statusDaySpa, 'day_spa')
    }, [statusDaySpa]);

    useEffect(() => {
      handleBackup(dataDaySpaText, statusDaySpaText, 'day_spa_text')
    }, [statusDaySpaText]);

    useEffect(() => {
      handleBackup(dataArticle, statusArticle, 'article')
    }, [statusArticle]);

    useEffect(() => {
      handleBackup(dataArticleText, statusArticleText, 'article_text')
    }, [statusArticleText]);

    useEffect(() => {
      handleBackup(dataContact, statusContact, 'contact')
    }, [statusContact]);

    useEffect(() => {
      handleBackup(dataTherapy, statusTherapy, 'therapy')
    }, [statusTherapy]);

    useEffect(() => {
      handleBackup(dataTherapyText, statusTherapyText, 'therapy_text')
    }, [statusTherapyText]);

    useEffect(() => {
      handleBackup(dataUnity, statusUnity, 'unity')
    }, [statusUnity]);

    useEffect(() => {
      handleBackup(dataUnityText, statusUnityText, 'unity_text')
    }, [statusUnityText]);

    function handleBackup(data, status, type){
      if (status.state === "loaded") {
        backupData(data, type)
      }
    }

    function backupData(data, type) {
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        saveAs(blob, type + '.json');
   }

  return (
    <div>Backup</div>
  )
}

export default Backup