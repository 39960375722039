import React from "react";
import data from "../../data.json";
import styled from "styled-components";
import { GlobalContext } from "../../contexts/GlobalContext";
import {
	StyledContainer,
	StylePageDescription,
	StyledSection,
	StyledBox,
	StyledBoxTitle,
	StyledCard,
	StyledCardImage,
	StyledParagraph,
} from "../../UI/Variables";

import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument, usePrismicDocumentByUID } from "@prismicio/react";
import { RichText } from "prismic-dom";

const StyledTitle = styled.h1`
  padding: 18px 0px;
  text-align: center;
  background: var(--c-yellow-dark);
  margin-bottom: 32px;

  @media (max-width: 782px) {
    padding: 18px 4px;
  }
`;

const StyledImage = styled.img`
  width: 50%;
  display: block;
  margin: auto;
  border-radius: 8px;
  margin-bottom: 32px;

  @media (max-width: 782px) {
    width: 88%;
  }
`;

const StyledTextPrismic = styled.div`
  p{
    word-spacing: 3px;
    margin-bottom: 24px;
    padding: 0px 20px;
    font-family: var(--type-third);
    line-height: 24px;
  }

  h2{
    margin-left: 18px;
    font-size: 20px;
  }

  li{
    list-style: circle;
    margin-left: 36px;
    margin-bottom: 4px;
    font-family: var(--type-third);
    line-height: 20px;

    &:last-of-type {
      margin-bottom: 32px;
    }

    &:first-of-type {
      margin-top: 12px;
    }
  }
`

function ArticleText() {
  window.scrollTo(0, 0);
  const global = React.useContext(GlobalContext);

  if(!localStorage.getItem("NAME_ARTICLE") || localStorage.getItem("NAME_ARTICLE") === "null") {
    global.setArticle("a-importancia-de-beber-muita-agua");
    localStorage.setItem("NAME_ARTICLE", "a-importancia-de-beber-muita-agua");
  };

  const articleGlobal =
    global.titleArticle !== ""
      ? global.titleArticle
      : localStorage.getItem("NAME_ARTICLE");

      const [articleData, setArticleData] = React.useState();
      const [article, statusArticle] = usePrismicDocumentByUID('article_text', articleGlobal)
      
      React.useEffect(() => {
        if (statusArticle.state === 'loaded') {
          setArticleData(article.data)
        }
      }, [statusArticle]);
  return (
    <div>
      <StyledTitle>{articleData && articleData.title[0].text}</StyledTitle>

      <StyledContainer>
        <StyledImage src={articleData && articleData.image.url} alt={articleData && articleData.image.alt} />
        <StyledSection>
          <StyledTextPrismic dangerouslySetInnerHTML={articleData && { __html: RichText.asHtml(articleData.description) }}></StyledTextPrismic>
        </StyledSection>
      </StyledContainer>
    </div>
  );
}

export default ArticleText;
