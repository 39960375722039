import React from "react";
import ImageSuccess from "../../Assets/sucesso_email_enviado.jpg";
import styled from "styled-components";

const StyledImage = styled.img`
  margin: auto;
  display: block;
  padding: 174px 0px;
`;

const Success = () => {
  return (
    <div>
      <StyledImage src={ImageSuccess} alt="Sucesso ao enviar email" />
    </div>
  );
};

export default Success;
