import React from "react";
import data from "../../data.json";
import styled from "styled-components";
import { GlobalContext } from "../../contexts/GlobalContext";
import {
	StyledContainer,
	StylePageDescription,
	StyledSection,
	StyledBox,
	StyledBoxTitle,
	StyledCard,
	StyledCardImage,
	StyledParagraph,
} from "../../UI/Variables";

import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument, usePrismicDocumentByUID } from "@prismicio/react";
import { RichText } from "prismic-dom";

const StyledTitle = styled.h1`
	padding: 18px 0px;
	text-align: center;
	background: var(--c-yellow-dark);
	margin-bottom: 32px;
`;

const StyledImage = styled.img`
  width: 30%;
  display: block;
  margin: auto;
  border-radius: 8px;
  margin-bottom: 32px;

  @media (max-width: 782px) {
    width: 90%;
  }
`;

const StyledTextPrismic = styled.div`
  p{
    word-spacing: 3px;
    margin-bottom: 24px;
    padding: 0px 20px;
    font-family: var(--type-third);
    line-height: 24px;
  }

  h2{
    margin-left: 18px;
    font-size: 20px;
  }

  li{
    list-style: circle;
    margin-left: 36px;
    margin-bottom: 4px;
    font-family: var(--type-third);
    line-height: 20px;

    &:last-of-type {
      margin-bottom: 32px;
    }

    &:first-of-type {
      margin-top: 12px;
    }
  }
`

const TherapyText = () => {
  window.scrollTo(0, 0);
  const global = React.useContext(GlobalContext);  

  if(!localStorage.getItem("NAME_THERAPY") || localStorage.getItem("NAME_THERAPY") === "null") {
    global.setTherapy("acupuntura");
    localStorage.setItem("NAME_THERAPY", "acupuntura");
  };

  const teraphyGlobal =
    global.titleTherapy !== ""
      ? global.titleTherapy
      : localStorage.getItem("NAME_THERAPY");
  
  const [therapyData, setTherapyData] = React.useState();
  const [therapy, statusTherapy] = usePrismicDocumentByUID('therapy_text', teraphyGlobal)
  
  React.useEffect(() => {
    if (statusTherapy.state === 'loaded') {
      setTherapyData(therapy.data)
    }
  }, [statusTherapy]);

  return (
    <div>
      <StyledTitle>{therapyData && therapyData.title[0].text}</StyledTitle>

      <StyledContainer>
        <StyledImage src={therapyData && therapyData.image.url} alt={therapyData && therapyData.image.alt} />
        <StyledSection>
          <StyledTextPrismic dangerouslySetInnerHTML={therapyData && { __html: RichText.asHtml(therapyData.description) }}></StyledTextPrismic>
        </StyledSection>
      </StyledContainer>
    </div>
  );
};

export default TherapyText;
