import React from "react";
import styled from "styled-components";
import {
  StyledContainer,
  StylePageDescription,
  StyledSection,
  StyledBox,
  StyledBoxTitle,
  StyledCard,
  StyledCardImage,
} from "../../UI/Variables";
import { GlobalContext } from "../../contexts/GlobalContext";
import { Link, NavLink, Routes, Route } from "react-router-dom";

import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-dom";

const UStyledText = styled.p`
  padding: 15px 10px 2px 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
`;

const UStyledCardButton = styled.button`
  display: block;
  margin: 16px auto 0 auto;
  margin-top: 16px;
  padding: 6px 22px;
  background: var(--c-yellow-light);
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: var(--c-white);
    background: var(--c-brown);
  }
`;

const UStyledCardImage = styled(StyledCardImage)`
  height: 340px;

  @media (max-width: 782px) {
    height: 264px;
  }
`;

const UStyledBox = styled(StyledBox)`
  align-items: end;
  margin-top: 32px;

  @media (max-width: 782px) {
    align-items: center;
  }
`;

const StyledTextPrismic = styled.div`
  p{
    font-family: var(--type-first);
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: var(--c-black);
    text-align: center;
    margin-bottom: 16px;
  }
`

const Unity = () => {
  const global = React.useContext(GlobalContext);

  const [dataUnity, statusUnity] = useSinglePrismicDocument('unity');
	const [dataUnityFormated, setDataUnityFormated] = React.useState(null);

	const [dataUnityText, statusUnityText] = useAllPrismicDocumentsByType('unity_text');
	const [dataUnityTextFormatted, setDataUnityTextFormatted] = React.useState([]);

	React.useEffect(() => {
		if (statusUnity.state === "loaded") {
			setDataUnityFormated(dataUnity.data);
		}
	}, [statusUnity]);


	React.useEffect(() => {
		if (statusUnityText.state === "loaded") {
      setDataUnityTextFormatted(dataUnityText);
		}
	}, [statusUnityText]);

  function handleClick02(item) {
    
      global.setUnity(item.uid)
      localStorage.setItem("NAME_UNITY", item.uid)
  }

  return (
    <StyledContainer>
      <StyledSection>        
            <StyledTextPrismic dangerouslySetInnerHTML={dataUnityFormated && { __html: RichText.asHtml(dataUnityFormated.description) }}></StyledTextPrismic>
      </StyledSection>

      <StyledSection>
        <UStyledBox>
          {dataUnityTextFormatted.length > 0 && dataUnityTextFormatted.map((item, index) => (
            <div key={item.data.title[0].text + index}>
              <StyledBoxTitle>{item.data.title[0].text}</StyledBoxTitle>
              <StyledCard>
                <UStyledCardImage src={item.data.image.url} alt={item.data.image.alt} />
                <UStyledText dangerouslySetInnerHTML={{ __html: RichText.asHtml(item.data.description) }}></UStyledText>
                <NavLink to="/unity_text">
                  <UStyledCardButton onClick={() => handleClick02(item)}>
                    Ver mais...
                  </UStyledCardButton>
                </NavLink>
              </StyledCard>
            </div>              
          ))}
        </UStyledBox>
      </StyledSection>
    </StyledContainer>
  );
};

export default Unity;
