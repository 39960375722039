import React from "react";
import styled from "styled-components";
import { GlobalContext } from "../../contexts/GlobalContext";
import data from "../../data.json";
import {
	StyledContainer,
	StyledBox,
	StyledBoxTitle,
	StyledCard,
	StyledCardImage,
	StylePageDescription,
	StyledSection,
} from "../../UI/Variables";
import { Link, NavLink, Routes, Route } from "react-router-dom";
import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-dom";

const AStyledImage = styled.img`
  width: 280px;
  height: 270px;
  border-radius: 8px;

  @media (max-width: 782px) {
    width: 252px;
    height: 258px;
  }
`;

const AStyledBox = styled.div`
  position: relative;
  margin-bottom: 260px;

  @media (max-width: 782px) {
    margin-bottom: 222px;
  }
`;

const AStyledCard = styled.div`
  position: absolute;
  background: var(--c-yellow);
  top: 236px;
  left: 60px;
  padding: 10px 20px;
  width: 80%;
  border-radius: 4px;

  @media (max-width: 782px) {
    top: 198px;
  }
`;

const AStyledContainer = styled.div`
  display: flex;
  gap: 50px 60px;
  flex-wrap: wrap;

  @media (max-width: 763px) {
    justify-content: center;
    gap: 0;
  }

  @media (min-width: 768px) and (max-width: 782px){
    justify-content: center;
    gap: 0 104px;
  }
`;

const AStyledP = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 782px) {
    -webkit-line-clamp: 5;
  }
`;

const AStyledCardButton = styled.button`
  display: block;
  margin: 16px auto 0 auto;
  margin-top: 16px;
  padding: 6px 22px;
  background: var(--c-yellow-light);
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: var(--c-white);
    background: var(--c-brown);
  }
`;

const AStyledTitle = styled.h2`
  font-family: var(--type-first);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: var(--c-black);
  letter-spacing: -0.5px;
  margin-bottom: 12px;
`;

const StyledTextPrismic = styled.div`
  p{
    font-family: var(--type-first);
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: var(--c-black);
    text-align: center;
    margin-bottom: 16px;
  }
`

function Article() {
  const global = React.useContext(GlobalContext);

  const [dataArticleText, statusArticleText] = useSinglePrismicDocument('article');
	const [dataArticleTextFormated, setArticleTextFormated] = React.useState(null);

  const [dataArticle, statusArticle] = useAllPrismicDocumentsByType('article_text');
	const [dataArticleFormated, setDataArticleFormated] = React.useState([]);

  React.useEffect(() => {
		if (statusArticleText.state === "loaded") {
			setArticleTextFormated(dataArticleText.data);
		}
	}, [statusArticleText]);

  React.useEffect(() => {
		if (statusArticle.state === "loaded") {
			setDataArticleFormated(dataArticle);
		}
	}, [statusArticle]);

  function handleClick(item) {
    global.setArticle(item.uid);
    localStorage.setItem("NAME_ARTICLE", item.uid);
  }

  return (
    <StyledContainer>
      <StyledSection>
        <StyledTextPrismic dangerouslySetInnerHTML={dataArticleTextFormated && { __html: RichText.asHtml(dataArticleTextFormated.description) }}></StyledTextPrismic>
      </StyledSection>
      <StyledSection>
        <AStyledContainer>
        {dataArticleFormated.length > 0 &&
            dataArticleFormated.map((item) => (
              <AStyledBox key={item.id}>
                <AStyledImage src={item.data.image.url} alt={item.data.image.alt} />
                <AStyledCard>
                  <AStyledTitle>{item.data.title[0].text}</AStyledTitle>
                  <AStyledP dangerouslySetInnerHTML={{ __html: RichText.asHtml(item.data.description) }}></AStyledP>
                  <NavLink to="/article_text">
                    <AStyledCardButton onClick={() => handleClick(item)}>
                      Continuar lendo...
                    </AStyledCardButton>
                  </NavLink>
                </AStyledCard>
              </AStyledBox>
            ))}
        </AStyledContainer>
      </StyledSection>
    </StyledContainer>
  );
}

export default Article;
