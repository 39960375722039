import React from "react";
import styled from "styled-components";
import { GlobalContext } from "../../contexts/GlobalContext";
import data from "../../data.json";
import {
  StyledContainer,
  StyledBox,
  StyledBoxTitle,
  StyledCard,
  StyledCardImage,
  StylePageDescription,
  StyledSection,
} from "../../UI/Variables";
import { Link, NavLink, Routes, Route } from "react-router-dom";
import slide01 from "../../Assets/slide01.png";
import slide02 from "../../Assets/slide02.png";
import slide03 from "../../Assets/slide03.png";

import daySolteiro from "../../Assets/day_spa_solteiro.jpeg";
import dayCasal from "../../Assets/day_spa_casal.jpeg";
import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-dom";
import { saveAs } from 'file-saver';

const HStyledImage = styled.img`
  min-width: 100%;
  max-width: 100%;

  @media (max-width: 782px) {
    min-height: 168px;
  }
`;

const HStyledBox = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 782px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
  }
`;

const HStyledCard = styled.div`
  width: 486px;

  @media (min-width: 782px) and (max-width: 1440px) {
    width: 486px;
  }

  @media (max-width: 782px) {
    width: 90%;
    margin: auto;
  }

  &:nth-of-type(2n) {
    height: 100px;
  }
`;

const HSubTitle = styled.h2`
  text-align: center;
  background: #bda85e;
  padding: 18px 0px 10px 0;
  border-radius: 32px 32px 0 0;
`;

const HFirstSubTitle = styled(HSubTitle)`
  padding: 26px 4px;
  min-height: 60px;
`;

const HStyledCardImage = styled.img`
  max-width: 100%;
  height: 366px;

  @media (min-width: 782px) and (max-width: 1440px) {
    height: 366px;
  }

  @media (max-width: 782px) {
    height: auto;
  }
`;

const HStyledCardImageCenter = styled.img`
  max-width: 486px;
  height: 332px;
  width: 486px;
  object-fit: cover;
  border-radius: 0 0 4px 4px;

  @media (min-width: 782px) and (max-width: 1440px) {
    width: 486px;
  }

  @media (max-width: 782px) {
    height: 286px;
    max-width: 100%;
    width: 100%;
  }
`;

const HStyledSection = styled(StyledSection)`
  background: #fff3ca;
  padding: 62px 0px;
  max-width: 100%;
`;

const HUStyledSection = styled(StyledSection)`
  max-width: 100%;
`;

//Home Day
const StyledPageTitle = styled.h1`
  font-family: var(--type-first);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: var(--c-brown);
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 16px;

  margin-top: -38px;

  &::after {
    content: "";
    display: block;
    width: 165px;
    height: 2px;
    background: var(--c-brown);
    margin: 0 auto;
  }
`;

const HDStyledCardCasal = styled(HStyledCard)`
  background: url(${dayCasal});
  width: 486px;
  height: 412px;
  background-size: cover;
  background-position-y: -116px;
  background-repeat: no-repeat;
  border-radius: 32px 32px 0 0;

  @media (max-width: 782px) {
    width: 346px;
    height: 368px;
  }
`;

const HDStyledCardRelax = styled(HStyledCard)`
  background: url(${daySolteiro});
  width: 486px;
  height: 412px;
  background-size: cover;
  background-position-y: 42px;
  background-repeat: no-repeat;
  border-radius: 32px 32px 4px 4px;

  @media (max-width: 782px) {
    width: 346px;
    height: 368px;
  }
`;

//Home Day
const HDStyledBox = styled(HStyledBox)`
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 782px) {
    flex-direction: column;
    align-items: center;
    gap: 32px 0;
  }
`;

const StyledMap = styled.div`
  margin-top: -32px;
  margin-bottom: -5px;
`;

function Home() {
  const global = React.useContext(GlobalContext);

  const [dataHome, statusHome] = useSinglePrismicDocument('home');
  const [carousel, setCarousel] = React.useState([]);
  const [highlight, setHighlight] = React.useState([]);
  const [highlightDaySpa, setHighlightDaySpa] = React.useState([]);

  React.useEffect(() => {
    if (statusHome.state === "loaded") {
      const dataHomeFormated = dataHome.data;
      setCarousel(dataHomeFormated.slide_banner);
      setHighlight(dataHomeFormated.highlight);
      setHighlightDaySpa(dataHomeFormated['highlight-day-spa']);
    }
  }, [statusHome]);

  function backupData(data) {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    saveAs(blob, 'backup.json');
  }

  function handleClick02(item) {
    if(item.relationship.type === "unity_text") {
      global.setUnity(item.relationship.uid)
      localStorage.setItem("NAME_UNITY", item.relationship.uid)
    }

    if(item.relationship.type === "therapy_text") {
      global.setTherapy(item.relationship.uid)
      localStorage.setItem("NAME_THERAPY", item.relationship.uid)
    }
  }

  return (
    <div>
      <swiper-container pagination="true" autoplay="true" dalay="3000">
        {carousel && carousel.length > 0 && carousel.map((item, index) =>
          <swiper-slide key={item.image.alt + index}>
          <NavLink to={item.relationship.type}>
            <HStyledImage src={item.image.url} alt={item.image.alt} />
          </NavLink>
        </swiper-slide>
      )}
      </swiper-container>

      <StyledContainer>
        <HUStyledSection>
          <HStyledBox>
            {highlight && highlight.length > 0 && highlight.map((item, index) =>
              <NavLink to={item.relationship.type} key={item.title[0].text + index}>
                <div>
                  <HStyledCard onClick={() => handleClick02(item)}>
                    <HFirstSubTitle>{item.title[0].text}</HFirstSubTitle>
                    <HStyledCardImageCenter src={item.image.url} alt={item.image.alt} />
                  </HStyledCard>
                </div>
              </NavLink>
            )}
          </HStyledBox>
        </HUStyledSection>
      </StyledContainer>

      <HStyledSection>
        <StyledPageTitle>Day Spa</StyledPageTitle>
        <HDStyledBox>
          {highlightDaySpa && highlightDaySpa.length > 0 && highlightDaySpa.map((item, index) =>
            <NavLink to={item.relationship.type} key={item.title1[0].text + index}>
            <HDStyledCardRelax>
              <HSubTitle>{item.title1[0].text}</HSubTitle>
            </HDStyledCardRelax>
          </NavLink>
        )}
        </HDStyledBox>
      </HStyledSection>

      <StyledMap>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.4336229940654!2d-46.5235707!3d-23.444818299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef531acc6f7fb%3A0x10e81f28f36fa1f2!2sSPA%20Julyo%20Ganiko!5e0!3m2!1spt-BR!2sbr!4v1684947406815!5m2!1spt-BR!2sbr"
          width="100%"
          height="440"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </StyledMap>
    </div>
  );
}

export default Home;
