import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledContainer } from "../../UI/Variables";
import { useFirstPrismicDocument, useAllPrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-dom";

const CStyledContainer = styled(StyledContainer)`
  display: flex;
  justify-content: center;
  gap: 42px;
  margin: 42px 0;

  @media (max-width: 820px) {
    flex-direction: column;
  }
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const StyledInput = styled.input`
  border: solid 1px #c9b87d;
  border-radius: 4px;
  padding: 6px 8px;
  width: 440px;

  &:hover {
    outline: none;
    border-color: #fb1;
    background: white;
    box-shadow: 0 0 0 3px #fea;
  }

  &:focus {
    outline: none;
    border-color: #fb1;
    background: white;
    box-shadow: 0 0 0 3px #fea;
  }

  @media (max-width: 820px) {
    width: auto;
  }
`;
const StyledTextarea = styled.textarea`
  border: solid 1px #c9b87d;
  border-radius: 4px;

  &:hover {
    outline: none;
    border-color: #fb1;
    background: white;
    box-shadow: 0 0 0 3px #fea;
  }

  &:focus {
    outline: none;
    border-color: #fb1;
    background: white;
    box-shadow: 0 0 0 3px #fea;
  }
`;
const StyledButton = styled.button`
  color: #000;
  background-color: #c9b87d;
  border: solid 2px #c9b87d;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
  width: 194px;
  border: none;

  &:hover {
    color: var(--c-white);
    background: var(--c-brown);
  }
`;

const Contact = () => {
  const [isMobile, setItMobile] = useState(false);
  const [isTablet, setItTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const [dataContactFormatted, setDataContactFormatted] = React.useState();
  const [dataContact, statusContact] = useSinglePrismicDocument('contact');

  React.useEffect(() => {
    if (statusContact.state === "loaded") {
      setDataContactFormatted(dataContact.data);
    }
  }, [statusContact]);

  console.log("dataContactFormatted", dataContactFormatted);

  function getDevice() {
    if (window.screen.width <= 640) {
      console.log("CELULAR");
      setItMobile(true);
    } else if (window.screen.width > 640 && window.screen.width <= 820) {
      console.log("TABLET");
      setItTablet(true);
    } else {
      console.log("DESKTOP");
      setIsDesktop(true);
    }
  }

  useEffect(() => {
    getDevice();
  }, []);
  function handleKeyUp(event) {
    let input = event.target;
    input.value = formatNumber(input.value);
  }

  function formatNumber(value) {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  }
  return (
    <CStyledContainer>
      <StyledForm action="https://api.staticforms.xyz/submit" method="post">
        <label htmlFor="name">Nome</label>
        <StyledInput type="text" name="name" id="name" />
        <label htmlFor="email">Email</label>
        <StyledInput type="email" name="email" id="email" />
        <label htmlFor="phoneNumber">Celular</label>
        <StyledInput
          type="text"
          name="phone"
          id="phoneNumber"
          onKeyUp={handleKeyUp}
        />
        <label htmlFor="message">Dúvida/Pergunta</label>
        <StyledTextarea
          name="message"
          id="message"
          cols="35"
          rows="10"
        ></StyledTextarea>

        <input
          type="hidden"
          name="accessKey"
          value="bd003c3b-ae76-4486-929c-c77671998425"
        />
        <input
          type="hidden"
          name="subject"
          value="Contato - Espaço Julyo Ganiko"
        />
        <input
          type="hidden"
          name="replyTo"
          value={dataContactFormatted && dataContactFormatted.email[0].text}
        />
        <input
          type="hidden"
          name="redirectTo"
          value="http://localhost:3000/success"
        ></input>
        <StyledButton type="submit">Enviar email</StyledButton>
      </StyledForm>

      {isDesktop && (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.4336229940654!2d-46.5235707!3d-23.444818299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef531acc6f7fb%3A0x10e81f28f36fa1f2!2sSPA%20Julyo%20Ganiko!5e0!3m2!1spt-BR!2sbr!4v1684947406815!5m2!1spt-BR!2sbr"
          width="40%"
          height="440"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      )}

      {(isMobile || isTablet) && (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.4336229940654!2d-46.5235707!3d-23.444818299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef531acc6f7fb%3A0x10e81f28f36fa1f2!2sSPA%20Julyo%20Ganiko!5e0!3m2!1spt-BR!2sbr!4v1684947406815!5m2!1spt-BR!2sbr"
          width="100%"
          height="440"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      )}
    </CStyledContainer>
  );
};

export default Contact;
