import "./App.css";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Unity from "./components/Unity/Unity";
import Therapy from "./components/Therapy/Therapy";
import TherapyText from "./components/TherapyText/TherapyText";
import DaySpa from "./components/DaySpa.jsx/DaySpa";

import { useEffect } from "react";
import { NamesText } from "./contexts/GlobalContext";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
import UnityText from "./components/UnityText/UnityText";
import Article from "./components/Article/Article";
import ArticleText from "./components/ArticleText/ArticleText";
import Home from "./components/Home/Home";
import Whatsapp from "./components/Whatsapp/Whatsapp";
import Contact from "./components/Contact/Contact";
import Success from "./components/Contact/Success";
import Video from "./components/Video/Video";
import Backup from "./components/Backup/Backup";
// register Swiper custom elements
register();

function App() {
  return (
    <BrowserRouter>
      <Header />

      <NamesText>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="therapy" element={<Therapy />}></Route>
          <Route path="therapy_text" element={<TherapyText />}></Route>
          <Route path="day_spa" element={<DaySpa />}></Route>
          <Route path="unity" element={<Unity />}></Route>
          <Route path="unity_text" element={<UnityText />}></Route>
          <Route path="article" element={<Article />}></Route>
          <Route path="article_text" element={<ArticleText />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="success" element={<Success />}></Route>
          <Route path="backup" element={<Backup />}></Route>
          <Route path="video" element={<Video />}></Route>
        </Routes>
      </NamesText>
      <Whatsapp />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
